<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-web</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(locale, i) in supportedLocales"
        :key="i"
        link
        @click="select(locale)"
      >
      <v-list-item-icon>
        <h3>{{ flag(locale) }}</h3>
      </v-list-item-icon>
        <v-list-item-title>{{ languageName(locale) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { countryCodeEmoji } from 'country-code-emoji';
import { i18nUtils } from '@/utils/i18n';

export default {
  name: 'LanguageSwitcher',
  computed: {
    supportedLocales() {
      return i18nUtils.supportedLocales;
    },
  },
  methods: {
    flag(locale) {
      const country = locale === 'en' ? 'us' : locale;
      return countryCodeEmoji(country);
    },
    languageName(locale) {
      return this.$t(`language_switcher.${locale}`);
    },
    select(locale) {
      if (this.$i18n.locale !== locale) {
        const localeCountry = `${locale}-${locale}`;
        const to = this.$router.resolve({ params: { localeCountry } });
        return i18nUtils.loadLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
      return locale;
    },
  },
};
</script>
